import { Storage } from '@ionic/storage';
import DataService from '@/services/storage-service';
import { storageName } from '@/utils';

class DefaultStorageService {
  private defaultStorage: Storage = new Storage({
    name: storageName,
  });

  private service: DataService = new DataService(this.defaultStorage);

  getData = (key: string) => this.service.getData(key);

  addData = (key: string, value: any) => this.service.addData(key, value);

  removeData = (key: string) => this.service.removeData(key);

  clear = () => this.service.clear();

  setEncryptionKey = (key: string) => this.service.setEncryptionKey(key);

  keys = () => this.service.keys();
}

export default DefaultStorageService;
