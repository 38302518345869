import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('@/views/SummaryPage.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/PaymentPage.vue'),
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('@/views/ThankYouPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/UninvitedPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // const { name } = to;
  // const nameString = (name || '').toString().toLowerCase();
  //
  // if (nameString !== 'error') {
  //   const account = to.query.account ?? null;
  //   const vendor = to.query.vendor ?? null;
  //   const auth = to.query.auth ?? null;
  //
  //   if (account === null || auth === null || vendor !== '2946496000037079004') {
  //     next({ name: 'error' });
  //   } else {
  //     next();
  //   }
  // }
  next();
});

export default router;
