/* eslint-disable implicit-arrow-linebreak */
// import sanitizeHtml from 'sanitize-html';
/** *
 * Filters
 * */

const append = (value: string, string: string) => value.concat(string);

const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const cut = (value: string, count: number) => (value.length < count ? value : value.slice(0, count));

const lowercase = (value: string) => value.toLowerCase();

const remove = (value: string, target: string) => value.replace(new RegExp(target, 'g'), '');

const removeFirst = (value: string, target: string) => value.replace(target, '');

const replace = (value: string, replacement: string) => value.replace(new RegExp(value, 'g'), replacement);

const replaceFirst = (value: string, replacement: string) => value.replace(value, replacement);

const slug = (value: string) =>
  value
    .toString()
    .toLowerCase()
    .trim()
    .replace(/&/g, '-and-')
    .replace(/[\s\W]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+|-+$/g, '-');

const titleCase = (value: string) => value.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const truncate = (value: string, count: number) => (value.length < count ? value : `${value.slice(0, count)}...`);

const uppercase = (value: string) => value.toUpperCase();

// const stripHtmlTags = (value: string) => sanitizeHtml(value);

const countWords = (string: string, skipStart?: string, skipEnd?: string) => {
  // Initialize the word counter
  let numWords = 0;
  const text = string;
  let skip = false;
  let before = '';
  let last = '';

  // Loop through the text
  // and count spaces in it
  for (let i = 0; i < text.length; i += 1) {
    const currentCharacter = text[i];

    // skipStart & skipEnd are used for giving instructions that do not count as part of the word count
    if (skipStart && currentCharacter === skipStart) skip = true;
    if (skipEnd && currentCharacter === skipEnd) skip = false;

    // assign before last character. Used for checking if there are repeated spaces
    before = last;
    // Check if the character is a space
    if (currentCharacter === ' ' && !skip && before !== ' ') {
      numWords += 1;
    }
    last = currentCharacter;
  }

  // Add 1 to make the count equal to
  // the number of words
  // (count of words = count of spaces + 1 if last word is not also space or full stop)
  if (text.length > 0 && last !== ' ') numWords += 1;

  return numWords;
};

const wordTruncate = (string: string, maxWords: number) => {
  const str = string.replace(/ \[[\s\S]*?]/g, '').trim();
  return str.split(' ').splice(0, maxWords).join(' ');
};

export {
  append,
  cut,
  remove,
  removeFirst,
  replaceFirst,
  replace,
  slug,
  // stripHtmlTags,
  lowercase,
  titleCase,
  truncate,
  uppercase,
  capitalize,
  countWords,
  wordTruncate,
};
