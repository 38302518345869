import { Storage } from '@ionic/storage';
import DataService from '@/services/storage-service';
import { storageName } from '@/utils';

class SecureStorageService {
  private secure: Storage = new Storage({
    name: `${storageName}_secure`,
  });

  private service: DataService = new DataService(this.secure);

  getData = (key: string) => this.service.getData(key);

  addData = (key: string, value: any) => this.service.addData(key, value);

  removeData = (key: string) => this.service.removeData(key);
}

export default SecureStorageService;
