import { defineStore } from 'pinia';
import { Voice } from '@/interfaces/voice';
import { apiGetRequest, apiUrl } from '@/api';
import { Link, Links, Meta } from '@/interfaces/api-response';
import { useLocation } from '@/stores/location';

interface MediaState {
  voices: Array<Voice>;
  meta: Meta | null;
  links: Links | null;
  filter: {
    per_page: number;
    'filter[platform]': 'phone';
    page: number;
    'filter[name]': string;
    'filter[accent]': string | null | 'New Zealand' | 'New Zealand,Maori' | 'Australian' | 'American' | 'British';
  };
  current: Voice | null;
  audioStatus: 'PLAYING' | 'PAUSED' | 'STOPPED';
}

const useMedia = defineStore('media', {
  state: (): MediaState => ({
    voices: [],
    meta: null,
    links: null,
    filter: {
      per_page: 25,
      'filter[platform]': 'phone',
      page: 1,
      'filter[name]': '',
      'filter[accent]': null,
    },
    current: null,
    audioStatus: 'STOPPED',
  }),
  getters: {
    linkLabel: () => {
      return (link: Link) => {
        if (link.label.toLowerCase() === '&laquo; previous') return '&laquo;';
        if (link.label.toLowerCase() === 'next &raquo;') return '&raquo;';
        return link.label;
      };
    },
    linkDisabled: () => {
      return (link: Link) => {
        return link.active || link.url === null || link.url === undefined;
      };
    },
  },
  actions: {
    async getVoices() {
      const url = apiUrl('voices', 'v2');
      const location = useLocation();
      const code = (location?.userGeo?.country.iso_code ?? '').toLowerCase();
      console.log(code);
      if (code === 'nz') this.filter['filter[accent]'] = 'New Zealand,Maori';
      else if (code === 'au') this.filter['filter[accent]'] = 'Australian';
      else if (code === 'gb') this.filter['filter[accent]'] = 'British';
      else if (code === 'us') this.filter['filter[accent]'] = 'American';
      else this.filter['filter[accent]'] = 'American';
      const res = await apiGetRequest(url, {
        params: this.filter,
      });
      const list = res.data.data as Array<Voice>;
      this.voices = this.prioritiseEthan(list);
      this.meta = res.data.meta;
      this.links = res.data.links;
    },

    prioritiseEthan(voices: Array<Voice>): Array<Voice> {
      const ethan = voices.find((v) => v.voice.name.toLowerCase() === 'ethan');
      if (ethan) {
        return [ethan, ...voices.filter((v) => v.id !== ethan.id)];
      }
      return voices;
    },

    paginate(link: Link) {
      const split = link.url?.split('?')[1];
      const params = new URLSearchParams(split);
      const page = params.get('page');
      this.filter.page = parseInt(page ?? '', 10);
    },
  },
});

export { MediaState, useMedia };
