import axios from 'axios';
import { getEnv } from '@/utils';

const API_HOST = getEnv('VUE_APP_API_HOST');
const WP_HOST = getEnv('VUE_APP_WP_HOST');

const GEO_API_URL = getEnv('VUE_APP_GEOLOCATION_URL') as string;

const apiUrl = (path: string, version?: string) => {
  if (version) return `${API_HOST}/api/${version}/${path}`;
  return `${API_HOST}/api/${path}`;
};

const wpUrl = (path: string, version: number) => `${WP_HOST}/wp-json/wp/v${version}/${path}`;

const apiGetRequest = (url: string, config?: object) => axios.get(url, config);

const apiPostRequest = (url: string, data?: object, config?: object) => axios.post(url, data, config);

const apiPutRequest = (url: string, data?: object, config?: object) => axios.put(url, data, config);

const apiDeleteRequest = (url: string, config?: object) => axios.delete(url, config);

export { apiUrl, wpUrl, apiGetRequest, apiPostRequest, apiPutRequest, apiDeleteRequest, API_HOST, WP_HOST, GEO_API_URL };
