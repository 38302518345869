import { toastController, alertController, AlertOptions } from '@ionic/vue';
import { checkmarkCircleOutline, informationOutline, warningOutline } from 'ionicons/icons';

interface ToastDetails {
  message: string;
  color?: string;
  duration?: number;
  icon?: string;
  position?: 'top' | 'bottom' | 'middle';
}

const showAlert = async (details: AlertOptions) => {
  const alert = await alertController.create({ ...details });
  await alert.present();
};

const showToast = async (details: ToastDetails) => {
  const toast = await toastController.create({ ...details });
  await toast.present();
};

const successToast = async (message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'bottom') => {
  await showToast({ message, color: 'success', icon: checkmarkCircleOutline, duration, position });
};

const warningToast = async (message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'bottom') => {
  await showToast({ message, color: 'warning', icon: warningOutline, duration, position });
};

const errorToast = async (message: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'bottom') => {
  await showToast({ message, color: 'danger', icon: informationOutline, duration, position });
};

export { successToast, warningToast, errorToast, showToast, showAlert };
