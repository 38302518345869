import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const getEnv = (key: string, defaultValue?: any) => process.env[key] || defaultValue;

const storageName = `${getEnv('VUE_APP_SITE')}_${getEnv('VUE_APP_ENV')}`;

const jsonEncode = (data: any) => JSON.stringify(data);

const jsonDecode = (data: string) => JSON.parse(data);

const dateUtil = (date?: string | number | dayjs.Dayjs | Date | null | undefined) => {
  dayjs.extend(relativeTime);
  return dayjs(date);
};

const currentDateToString = () => {
  const date = new Date();
  return date.toDateString();
};

const randomId = () => parseInt((Math.random() * 100000).toFixed(0), 10);

const currentDateTimeToString = (): string => {
  const date = new Date();
  return `${date.toDateString()} ${date.toTimeString()}`;
};

const unixTimeStamp = (dateString?: string): number => Date.parse(dateString ?? currentDateTimeToString()) / 1000;

const audioDurationString = (value: number) => {
  const hhmmss = new Date(value * 1000).toISOString().substr(11, 8);
  return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
};

const logo: string = getEnv('VUE_APP_LOGO', '');

export { getEnv, jsonEncode, jsonDecode, dateUtil, currentDateToString, randomId, currentDateTimeToString, unixTimeStamp, audioDurationString, logo, storageName };
