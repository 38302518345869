import { defineStore } from 'pinia';
import { Voice } from '@/interfaces/voice';
import { countWords } from '@/utils/filters';
import Finance from '@/classes/finance';
import { CurrencyName } from '@/interfaces/currency';
import { storage } from '@/utils/storage';
import { jsonDecode, jsonEncode } from '@/utils';
import { isString } from 'lodash';
import { apiGetRequest, apiPostRequest, apiUrl } from '@/api';
import { ApiProduct } from '@/interfaces/api-response';
import { useLocation } from '@/stores/location';
import { CartForm, CartOrder, CreateOrderResponse, CrmDeal, PaymentPayload, Price } from '@/interfaces/cart';
import { PaymentIntent } from '@stripe/stripe-js';
import { useNavigation } from '@/stores/navigation';
import { loadingController } from '@ionic/vue';
import { useSetup } from '@/stores/setup';

interface CartState {
  form: CartForm;
  voice: Voice | null;
  currency: CurrencyName;
  product: ApiProduct | null;
  price: Price;
  allowed: boolean;
  submitting: boolean;
  order?: CreateOrderResponse;
  params: {
    auth: string | null;
    account: string | null;
    vendor: string | null;
  };
  paymentIntent?: PaymentIntent;
}

const useCart = defineStore('cart', {
  state: (): CartState => {
    const setup = useSetup();

    return {
      voice: null,
      product: null,
      currency: 'usd',
      allowed: true,
      submitting: false,
      form: {
        First_Name: '',
        Last_Name: '',
        Email: '',
        Company: '',
        Phone: '',
        Voicing_Script: '',
        Vendor: setup.vendorId,
        Currency: 'USD',
        Voice_Talent_NickName: '',
        Platform: setup.platform,
        LFM_Department: setup.lfmDepartment,
        Reference: setup.jobReference,
        Payment_Method: 'stripe',
        Amount: 0,
        Country: '',
        Mailing_Country: '',
        Mailing_List_Subscribed: '',
        Trigger_Field: setup.triggerField,
        Deal_Name_Prefix: setup.dealNamePrefix,
        SKU: setup.productSku,
        Notes_for_Producer: '',
        Voice_Talents: [],
      },
      price: {
        usd: 0,
        nzd: 0,
        aud: 0,
        eur: 0,
        gbp: 0,
        usd_uae: 0,
      },
      params: {
        auth: null,
        account: null,
        vendor: null,
      },
    };
  },
  getters: {
    ready: (state: CartState): boolean => {
      return (
        state.form.First_Name.length > 0 &&
        state.form.Last_Name.length > 0 &&
        state.form.Email.length > 0 &&
        state.form.Company.length > 0 &&
        state.form.Phone.length > 0 &&
        state.form.Voicing_Script.length > 0 &&
        //
        state.voice !== null
      );
    },
    scriptWordCount: (state: CartState) => countWords(state.form.Voicing_Script ?? '', '[', ']'),
    parseMoney(state: CartState) {
      const money = new Finance(state.currency);
      return (amount: number | string) => money.parseMoney(amount);
    },
    displayPrice(state: CartState) {
      return () => this.parseMoney(state.price[this.currency]);
    },
    dealAmount(state: CartState) {
      return (deal?: CrmDeal) => (deal?.Amount ?? 0) * (state.currency === 'nzd' ? 1.15 : 1);
    },
    productPrice(state: CartState) {
      return () => this.parseMoney(state.price[this.currency] * (this.currency === 'nzd' ? 1.15 : 1));
    },
    hasParams: (state: CartState) => {
      return (state.order && state.params.auth && state.params.account && (state.params.vendor?.length ?? 0) > 0) as unknown as boolean;
    },
    toPayment: (state: CartState) => {
      return (state.order && state.order.status === 'Not Paid' && state.order.payment?.status !== 'succeeded') as unknown as boolean;
    },
    currentPrice: (state: CartState) => state.price[state.currency],
    calcTotals(state: CartState) {
      return () => {
        const discount = 0;
        const price = this.currentPrice;
        const tax = state.currency.toLowerCase() === 'nzd' ? price * 0.15 : 0;
        const subtotal = price;
        const total = subtotal + tax;
        const fee = 0;
        return { baseTotal: total, fee, subtotal, total, tax, discount };
      };
    },
    deal: (state: CartState) => state.order?.crm_deal,
    payment: (state: CartState) => state.order?.payment,
    paid: (state: CartState) => (state.order && (state.order.status === 'Paid' || state.order.payment?.status === 'succeeded')) as unknown as boolean,
    // item: (state: CartState) => state.order?.item,
    orderPayload(state: CartState) {
      return () => {
        const setup = useSetup();
        const totals = this.calcTotals();
        const { form } = state;
        const location = useLocation();
        const country = location.userGeo?.country.name ?? '';
        return {
          currency: state.currency,
          country,
          total: totals.total,
          discount: totals.discount,
          lfm_department: setup.lfmDepartment.toLowerCase(),
          coupon: '',
          form: {
            payment_method: form.Payment_Method.toLowerCase(),
            first_name: form.First_Name,
            last_name: form.Last_Name,
            email: form.Email,
            phone: form.Phone,
            company_name: form.Company,
            coupon_code: '',
            coupon: null,
          },
          trigger_field: setup.triggerField,
          items: [
            {
              First_Name: form.First_Name,
              Last_Name: form.Last_Name,
              Email: form.Email,
              Company: form.Company,
              Phone: form.Phone,
              Voicing_Script: form.Voicing_Script,
              Vendor_ID: form.Vendor,
              Currency: state.currency,
              Voice_Talent_NickName: form.Voice_Talents.map((v) => v).join(','),
              Product_Type: setup.productType,
              Platform: setup.platform,
              LFM_Department: setup.lfmDepartment,
              Job_Reference: setup.jobReference,
              Payment_Method: form.Payment_Method,
              Amount: this.currentPrice,
              Tax: totals.tax,
              Discount: totals.discount,
              Coupon: '',
              Country: country,
              Mailing_Country: country,
              // Mailing_List_Subscribed: '',
              Trigger_Field: setup.triggerField,
              Deal_Name_Prefix: setup.dealNamePrefix,
              Notes_for_Producer: setup.generateNotes(form.Notes_for_Producer, state.params.auth, state.params.account),
              Voice_Talents: form.Voice_Talents,
              Primary_Voices: [
                {
                  name: state.voice?.voice.name,
                  accent: state.voice?.accent,
                },
              ],
              Alternate_Voices: [],
              SKU: setup.productSku,
              File: null,
              Record_Guide: null,
            },
          ],
        };
      };
    },
    paymentPayload(state: CartState): PaymentPayload {
      return {
        order_id: state.order?.id ?? '',
        payment_method: state.form.Payment_Method.toLowerCase(),
        payment_id: state.order?.payment?.id ?? '',
        currency: state.order?.currency ?? '',
        lfm_department: this.orderPayload().lfm_department,
        trigger_field: this.orderPayload().trigger_field,
      };
    },
  },
  actions: {
    async commit(key: string, data: any) {
      let toSave = data;
      if (!(Number.isInteger(data) || isString(data))) {
        const encode = jsonEncode(data);
        toSave = jsonDecode(encode);
      }
      await storage.addData(key, toSave);
    },
    async commitForm() {
      await this.commit('cart', this.form);
    },
    async commitVoice(voice: Voice) {
      this.voice = voice;
      this.form.Voice_Talent_NickName = this.voice.voice.name;
      this.form.Voice_Talents = [this.voice.voice.name];
      await this.commit('voice', this.voice);
    },
    async getLocalPaymentIntent() {
      const res = await storage.getData('payment_intent');
      if (res) this.paymentIntent = res;
    },
    async commitPaymentIntent(paymentIntent?: PaymentIntent) {
      this.paymentIntent = paymentIntent;
      await this.commit('payment_intent', this.paymentIntent);
    },
    async getProduct() {
      const setup = useSetup();
      const url = apiUrl(`products/${setup.productSku}`, 'ext/v1');
      const res = await apiGetRequest(url);
      this.product = res.data.data as ApiProduct;
      this.price = {
        usd: this.product.USD,
        nzd: this.product.NZD,
        aud: this.product.AUD,
        eur: this.product.EUR,
        gbp: this.product.GBP,
        usd_uae: this.product.USD_UAE,
      };
    },
    async commitOrder() {
      await this.commit('order', this.order);
    },
    async getDeal(dealId: string | number, addToOrder?: boolean) {
      this.submitting = true;
      const url = apiUrl('sales/get-deal', 'v1');
      const res = await apiPostRequest(url, {
        deal_id: dealId,
      });
      const deal = res.data as CrmDeal;
      if (addToOrder) {
        if (this.order) this.order.crm_deal = deal;
        if (deal.Payment_Status === 'Paid or Invoiced' && this.order) {
          this.order.status = 'Paid';
        }
        await this.commitOrder();
      }
      return deal;
    },
    async createOrder() {
      const url = apiUrl('audio-creator/save', 'v1');
      const res = await apiPostRequest(url, this.orderPayload());
      this.order = res.data;
      await this.commitOrder();
      await this.getDeal(this.order?.items[0].deal_id ?? '', true);
    },
    async confirmOrder() {
      this.submitting = true;
      const url = apiUrl('sales/save-order', 'v1');
      const res = await apiPostRequest(url, this.form);
      this.order = res.data;
      await this.commitOrder();
      await this.getDeal(this.order?.items[0].deal_id ?? '', true);
    },
    async setPayment(paymentIntent: PaymentIntent) {
      if (this.order) this.order.payment = paymentIntent;
      await this.commitOrder();
    },
    async submitPayment(paymentIntent?: PaymentIntent): Promise<{ status: PaymentIntent.Status; order: CartOrder } | null> {
      if (paymentIntent) {
        const url = apiUrl('orders/payment-intent/submit', 'v1');
        const res = await apiPostRequest(url, {
          payment_id: paymentIntent.id,
          order_id: this.order?.id,
        });
        const { order, status } = res.data;
        this.order = { ...this.order, ...order };
        await this.commitOrder();
        return {
          status,
          order,
        };
      }
      return null;
    },
    async clear() {
      await storage.removeData('cart');
      await storage.removeData('order');
      await storage.removeData('voice');
      await storage.removeData('payment_intent');
    },
    async getLocal() {
      const cart = await storage.getData('cart');
      if (cart) this.form = cart as CartForm;
      const voice = await storage.getData('voice');
      if (voice) {
        this.voice = voice as Voice;
        this.form.Voice_Talent_NickName = this.voice.voice.name;
        this.form.Voice_Talents = [this.voice.voice.name];
      }
      const order = await storage.getData('order');
      if (order) this.order = order as CreateOrderResponse;
    },
    setParams() {
      const params = new URLSearchParams(window.location.search);
      this.params = {
        auth: params.get('auth'),
        account: params.get('account'),
        vendor: params.get('vendor'),
      };
      this.form.Auth = this.params.auth ?? '';
      this.form.Account = this.params.account ?? '';
      if (this.params.account && this.params.account !== this.form.Phone) {
        this.form.Phone = this.params.account;
      }
      // this.form.Notes_for_Producer = `Use the following details to upload data back to client\nVendorName:Vxt\nVendorID:${this.form.Vendor}\nAccount:${this.form.Account}\nAuth:${this.form.Auth}`;
    },

    async savePayment(payment: PaymentIntent) {
      if (payment.status === 'succeeded') {
        if (this.order) {
          this.order.payment = payment;
          await this.commitOrder();
        }
      }
    },

    async sendPayment() {
      const loading = await loadingController.create({
        message: 'Updating your order...',
      });

      await loading.present();

      const url = apiUrl('audio-creator/submit-payment', 'v1');
      try {
        const res = await apiPostRequest(url, this.paymentPayload);
        this.order = {
          ...this.order,
          ...res.data,
        } as CreateOrderResponse;
        await this.commitOrder();
      } catch (e) {
        //
      }
      await loading.dismiss();
    },

    async submitOrderPayment() {
      await this.sendPayment();
      if (this.order && this.order.status === 'Paid') {
        // Redirect to thank you page if paid //
        const { goTo } = useNavigation();
        await goTo('thank-you', true);
      }
    },

    reset() {
      const setup = useSetup();
      const navigation = useNavigation();
      this.form = {
        First_Name: '',
        Last_Name: '',
        Email: '',
        Company: '',
        Phone: '',
        Voicing_Script: '',
        Vendor: setup.vendorId,
        Currency: this.currency,
        Voice_Talent_NickName: '',
        Platform: setup.platform,
        LFM_Department: setup.lfmDepartment,
        Reference: setup.jobReference,
        Payment_Method: 'stripe',
        Amount: this.currentPrice,
        Country: '',
        Mailing_Country: '',
        Mailing_List_Subscribed: '',
        Trigger_Field: setup.triggerField,
        Deal_Name_Prefix: setup.dealNamePrefix,
        SKU: setup.productSku,
        Notes_for_Producer: '',
        Voice_Talents: [],
      };
      this.order = undefined;
      const params = setup.requireVendor ? this.params : undefined;
      navigation.goTo('home', true, params);
    },

    async init() {
      await this.getProduct();
      await this.getLocal();
      await this.getLocalPaymentIntent();
      const location = useLocation();
      this.currency = location.currency;
      this.form.Amount = this.price[this.currency];
      this.form.Currency = this.currency.toUpperCase();
      this.form.Country = location.userGeo?.country.name ?? '';
      this.form.Mailing_Country = location.userGeo?.country.name ?? '';
      // this.form.SKU = this.product?.sku ?? '';
      this.setParams();
    },
  },
});

export { CartState, useCart };
