import { getEnv } from '@/utils';
import { defineStore } from 'pinia';
// @ts-ignore
import vxt from '@/assets/images/logos/vxt.png';
// @ts-ignore
import ohvo from '@/assets/images/logos/ohvo.png';

type SiteType = 'ohvo' | 'vxt';

const site: SiteType = getEnv('VUE_APP_SITE', 'ohvo');
const env: string = getEnv('VUE_APP_ENV', 'live');

const useSetup = defineStore('setup', {
  state: () => ({
    site,
    env,
    isVxt: site === 'vxt',
  }),
  getters: {
    logo: (state) => (state.isVxt ? vxt : ohvo),
    titleGray: (state) => (state.isVxt ? 'Get' : 'Order now'),
    ctoText: (state) => (state.isVxt ? 'Order Now' : 'Hurry! For a Limited Time'),
    titleDark: (state) => (state.isVxt ? 'a professional Greeting for your VXT Voicemail' : 'a Professional Greeting for your Voicemail'),
    subtitle: (state) =>
      state.isVxt ? 'Pick your preferred Voice Talent and type in your Script (up to 30 seconds) below. Within 3 working days we will record and upload your new Professional Voicemail Greeting into your VXT Greetings. We will also email you a downloadable copy of the audio.' : 'Pick your preferred Voice Talent below and type in your Script (max. 30 seconds). We will deliver your audio by email within 3 working days. Have a mobile voicemail? We may be able to upload this to your voicemail too!',
    messageHelpText: (state) => (state.isVxt ? '' : '(OPTIONAL) WHAT WOULD YOU LIKE YOUR GREETING TO SAY? OR WE CAN WRITE IT'),
    messagePlaceholderText: (state) => (state.isVxt ? 'What would you like to say with your voice greeting?' : 'Input message here'),
    showProductionNotes: (state) => !state.isVxt,
    productSku: (state) => (state.isVxt ? 'PHONE-VO' : 'PHONE-VO-2'),
    vendorId: (state) => (state.isVxt ? '2946496000037079004' : ''),
    requireVendor: (state) => state.isVxt,
    storageName: (state) => `${state.site}_${state.env}`,
    jobReference: () => 'OHVO',
    lfmDepartment: () => 'OHVO',
    dealNamePrefix: () => 'Professional Greeting',
    triggerField: () => 'Send Ohvo Thankyou Email',
    platform: () => 'Phone',
    productType: () => 'Phone',
    generateNotes() {
      return (productionNotes: string, auth?: string | null, account?: string | null) => {
        if (this.isVxt) {
          return `${productionNotes}\n\n Upload details: \nAccount: ${account}\nAuth: ${auth}`;
        }
        return productionNotes;
      };
    },
  },
  actions: {},
});

export { site, useSetup, SiteType };
