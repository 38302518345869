import { defineStore } from 'pinia';
import { ApiCountry, Location } from '@/interfaces/location';
import { currentDateToString, jsonDecode, jsonEncode } from '@/utils';
import { storage } from '@/utils/storage';
import { apiGetRequest, apiUrl, GEO_API_URL } from '@/api';
import { CurrencyName } from '@/interfaces/currency';

interface LocationState {
  userGeo: Location | null;
  countries: Array<ApiCountry>;
  num: number;
}

const useLocation = defineStore('location-store', {
  state: (): LocationState => ({
    userGeo: null,
    countries: [],
    num: 0,
  }),
  getters: {
    nowDate: (): string => currentDateToString(),
    currency(): CurrencyName {
      if (this.userGeo) {
        const { continent, country } = this.userGeo;
        const isoCode = country.iso_code.toLowerCase();
        const continentCode = continent.code.toLowerCase();
        if (isoCode === 'gb') return 'gbp';
        if (continentCode === 'eu' && isoCode !== 'ua') return 'eur';
        if (isoCode === 'nz') return 'nzd';
        if (isoCode === 'au') return 'aud';
        if (isoCode === 'ae') return 'usd_uae';
      }
      return 'usd';
    },
    defaultAccent() {
      const currencyName = this.currency;
      if (currencyName === 'nzd') return 'New Zealand';
      if (currencyName === 'aud') return 'Australian';
      if (currencyName === 'gbp') return 'British';
      if (currencyName === 'usd') return 'American';
      return '';
    },
  },
  actions: {
    async geoChecked() {
      const checkDate = await storage.getData('geo_checked');
      return checkDate && checkDate === this.nowDate;
    },
    currentLocation(): Promise<Location | null> {
      return new Promise((resolve, reject) => {
        fetch(GEO_API_URL, {
          method: 'GET',
        })
          .then((response) => response.json())
          .then(async (result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    },
    getCountries(): Promise<Array<ApiCountry>> {
      const url = apiUrl('orders/countries', 'v1');
      return new Promise((resolve, reject) => {
        apiGetRequest(url, {
          params: {
            sort_by: 'phone_code',
          },
        })
          .then((response) => {
            resolve(response.data.data as Array<ApiCountry>);
          })
          .catch((error) => reject(error));
      });
    },
    async setCountries() {
      const localCountries = await storage.getData('countries');
      if (localCountries) this.countries = localCountries as Array<ApiCountry>;
      else {
        this.countries = await this.getCountries();
        if (this.countries.length) {
          this.countries = this.countries.filter((c) => c.phone_code.trim().length);
          await storage.addData('countries', jsonDecode(jsonEncode(this.countries)));
        }
      }
    },
    async setLocation() {
      const local = await storage.getData('location');
      const checked = await this.geoChecked();
      if (local && checked) this.userGeo = local as Location;
      else {
        this.userGeo = await this.currentLocation();
        if (this.userGeo) {
          await storage.addData('location', jsonDecode(jsonEncode(this.userGeo)));
          await storage.addData('geo_checked', this.nowDate);
        }
      }
    },
    async init() {
      // await this.setCountries();
      await this.setLocation();
    },
  },
});

export { useLocation, LocationState };
