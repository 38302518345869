// UseIonRouterResult

import { defineStore } from 'pinia';
import { useIonRouter, UseIonRouterResult } from '@ionic/vue';
import { useCart } from '@/stores/cart';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

interface NavigationState {
  route: RouteLocationNormalizedLoaded;
  router: UseIonRouterResult;
}

interface NavItem {
  prev: string | null;
  next: string | null;
}

const useNavigation = defineStore('navigation', {
  state: (): NavigationState => ({
    route: useRoute(),
    router: useIonRouter(),
  }),
  getters: {
    nav: (state: NavigationState): NavItem => {
      const current = (state.route.name ?? 'home').toString().toLowerCase();
      if (current === 'summary') {
        return {
          prev: 'home',
          next: 'payment',
        };
      }
      if (current === 'payment') {
        return {
          prev: null,
          next: 'thank-you',
        };
      }
      return {
        prev: null,
        next: 'summary',
      };
    },
  },
  actions: {
    goTo(name: string, replace?: boolean, query?: object) {
      const cart = useCart();
      if (replace) {
        this.router.replace({ name, query: { ...cart.params, ...query } });
      } else {
        this.router.push({ name, query: cart.params });
      }
    },
    next() {
      if (this.nav.next) {
        this.goTo(this.nav.next);
      }
    },
    back() {
      if (this.nav.prev) {
        this.goTo(this.nav.prev);
      }
    },
    refresh(route: RouteLocationNormalizedLoaded, router: UseIonRouterResult) {
      this.route = route;
      this.router = router;
    },
  },
});

export { useNavigation, NavigationState };
