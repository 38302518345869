import currencyJs from 'currency.js';
import { CurrencyName, CurrencySymbol } from '@/interfaces/currency';

class Finance {
  currency: CurrencyName;

  precision: number;

  constructor(currency?: CurrencyName, precision = 2) {
    this.currency = currency || 'usd';
    this.precision = precision;
  }

  currencySymbol = (): CurrencySymbol => {
    const currencyLower = this.currency.toLowerCase();
    return <CurrencySymbol>{
        usd: '$',
        eur: '€',
        gbp: '£',
        nzd: 'NZ$',
        aud: 'A$',
        usd_uae: '$',
      }[currencyLower] || '$';
  };

  money = (amount: number | string) => {
    const symbol = this.currencySymbol();
    return currencyJs(amount, { symbol, precision: this.precision });
  };

  parseMoney = (amount: number | string): string => this.money(amount).format();

  tax = (amount: number | string, rate: number) =>
    this.money(amount)
      .multiply(rate / 100)
      .format();

  amountWithTax = (amount: number | string, tax: number | string) => this.money(amount).add(tax).format();
}

export default Finance;
