import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { IonicVue, loadingController } from '@ionic/vue';
import { useCart } from '@/stores/cart';
import { useLocation } from '@/stores/location';
import { useSetup } from '@/stores/setup';
import App from './App.vue';
import router from './router';

/* Core CSS required for Ionic components to work properly */
import './assets/fonts/avenir/stylesheet.css';
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Tailwindcss */
import './assets/css/output/app.css';

const pinia = createPinia();

const app = createApp(App).use(IonicVue).use(router).use(pinia);

const cart = useCart();
const setup = useSetup();
const location = useLocation();

router.isReady().then(async () => {
  const loader = await loadingController.create({
    message: `Loading ${setup.dealNamePrefix} Creator...`,
    animated: true,
  });

  await loader.present();
  await location.init();
  await cart.init();
  cart.currency = location.currency;

  // Guard Routes
  router.beforeEach((to, from, next) => {
    if (to.name !== 'home') {
      if (to.name === 'payment' && !cart.toPayment) {
        if (cart.paid) next({ name: 'thank-you' });
        else next({ name: 'home' });
      }
      if (to.name === 'thank-you' && !cart.paid) next({ name: 'home' });
    }
    if (cart.order && !cart.paid && to.name !== 'payment') {
      next({ name: 'payment' });
    }
    next();
  });
  if (setup.requireVendor) {
    console.log('Require vendor');
    if (cart.params.vendor !== setup.vendorId || !cart.params.account || !cart.params.auth) {
      await router.replace({ name: 'error' });
    }
  }
  app.mount('#app');
  await loader.dismiss();
});
